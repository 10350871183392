<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 >Bienes Raices</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Bienes Raices</li>
                
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="project-area-six pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>JGM</span>
            <p>Una de las mejores inversiones de capital son las propiedades, y con nosotros puedes estar seguro que adquirir una propiedad ubicada en una de las mas hermosas costas del estado de oaxaca.</p>

            <h2 data-aos="fade-right" data-aos-duration="3000">CASAS</h2>
            <p>Casas habitables.</p>
        </div>

        <div class="project-wraps">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-1">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-2">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-2 col-md-12">
                    <div class="single-project">
                        <div class="project-image bg-3">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-4">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-8 col-md-6">
                    <div class="single-project">
                        <div class="project-image bg-5">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title">
            <span>JGM</span>
            <h2 data-aos="fade-right" data-aos-duration="3000">RESIDENCIALES Y FRACCIONAMIENTOS</h2>
            <p>Propiedades en zonas residenciales y fraccionamientos.</p>
        </div>


        <div class="project-wraps">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="single-project">
                        <div class="project-imageR bg-1">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-project">
                        <div class="project-imageR bg-2">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="single-project">
                        <div class="project-imageR bg-3">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-imageR bg-4">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-8 col-md-6">
                    <div class="single-project">
                        <div class="project-imageR bg-5">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section-title">
            <span>JGM</span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Lotes</h2>
            <p>Propiedades sin construcción</p>
        </div>
        

        <div class="project-wraps">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-project">
                        <div class="project-imageL bg-1">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-8 col-md-12">
                    <div class="single-project">
                        <div class="project-imageL bg-2">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-8 col-md-6">
                    <div class="single-project">
                        <div class="project-imageL bg-4">
                            <div class="price-wrap"></div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>

    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span> </span>
            <h2>Agentes de ventas </h2>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Persona 2</p>
                        <h3>Agente de ventas</h3>
                        <span>cel: 123 456 7890</span>
                    </div>
                </ng-template>
                    <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p> persona 1</p>
                        <h3>Agente de ventas</h3>
                        <span>cel: 123 456 7890</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>