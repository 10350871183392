<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Acerca de </h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Acerca de </li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Nuestro Coorporativo</span>
                        <h2 data-aos="fade-right" data-aos-duration="3000">Brindamos los mejores servicios</h2>
                        <p align="justify">Trabajar con nosotros es una decisión inteligente si quieres asegurarte de tener un manejo eficiente y efectivo de tus finanzas en materia de negocios e inversiones. Contamos con un equipo de expertos para servicios de asistencia jurídica, bienes raíces, construcción, financiamiento, asi como venta y renta de autos.</p>
                        <p align="justify">Nos enorgullece ofrecer soluciones efectivas y eficientes que no solo cumplen con tus obligaciones contables y fiscales, sino que también te brindan la tranquilidad y confianza de que tu negocio está en las mejores manos.
                           
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span> </span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Fundador </h2>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/ceo/ceo.jpg" alt="img">
                        <p>Lider de Alta Direccion y Estratega</p>
                        <h3>Lic. Jesus Garcia Monjaraz</h3>
                        <span>CEO del coorporativo</span>
                    </div>
                </ng-template>
                <!-- para usar en un carrusel de contenido    <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span></span>
                    </div>
                </ng-template>-->
            </owl-carousel-o>
        </div>
    </div>
</section>

<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>JGM</span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Nuestras bases</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/mision.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Misión</h3>
                        <p align="justify">Ser el socio confiable y estratégico de nuestros clientes en materia jurídica, bienes raíces, construcción, financiera, renta y venta de vehículos.</p>
                        <p align="justify">Utilizamos tecnología de vanguardia para mejorar la eficiencia de nuestros servicios, lo que nos permite brindar soluciones efectivas y adaptadas a las necesidades y presupuesto de cada cliente.</p>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/vision.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visión</h3>
                        <p align="justify">Ser reconocidos como líderes en el mercado de servicios profesionales, gracias a la calidad y personalización de nuestros servicios, y al compromiso constante con la mejora continua y la innovación. </p>
                        <p align="justify">Nos enfocamos en ser el socio estratégico de nuestros clientes, brindándoles soluciones integrales y adaptadas a sus necesidades y presupuesto, para ayudarles a alcanzar el éxito.

                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/valores.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Valores</h3>
                        <p align="justify"><b>Profesionalismo:</b> Brindar servicios de alta calidad.<br>
                        <b>Transparencia:</b> Mantener una comunicación clara.<br>
                        <b>Innovación:</b> Mejora de servicios y procesos.<br>
                        <b>Compromiso:</b> Estrecha colaboración con el cliente.<br>
                        <b>Integridad:</b> Honestidad y ética en todo momento.<br>
                        <b>Excelencia:</b> Calidad y atención en cada proceso.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2 data-aos="fade-right" data-aos-duration="3000">Nuestro Alcance</h2>
                    <p>El corporativo J G M BUSINESS AND INVESTMENT GROUP no sólo ejercen sus funciones de negocios e inversiones, sino que se encargan de acompañar a nuestros clientes durante todo el proceso hasta la solución final de su problema, con el mejor trato, tanto a nivel profesional, como a nivel personal. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="#" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>JGM</span>
                        <h2 data-aos="fade-right" data-aos-duration="3000"> Aspectos que nos caracterizan</h2>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Expertos capacitados</li>
                        <li><i class="flaticon-correct"></i> Servicios personalizados</li>
                        <li><i class="flaticon-correct"></i> Ahorro tiempo y dinero</li>
                        <li><i class="flaticon-correct"></i> Asesoria y soporte constante</li>
                        <li><i class="flaticon-correct"></i> Servicios Integrales</li>
                        <li><i class="flaticon-correct"></i> Enfoque en el cliente</li>
                        <li><i class="flaticon-correct"></i> Soluciones efectivas</li>
                        <li><i class="flaticon-correct"></i> Experiencia y conocimiento</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>


