<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Origen</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Origen</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="services-img mb">
                    <img data-aos="fade-left" data-aos-duration="2500" src="assets/images/solutions-details/idea.jpg" alt="Image">
                </div>
            </div>

            <div class="col-12">
                <div class="services-details-text">
                    <h2 data-aos="fade-down" data-aos-duration="3000">¿Como nace este corporativo?</h2>
                    <p align="justify">J G M BUSINESS AND INVESTMENT GROUP, fue creado por Lic. Jesus Garcia Monjaraz, y nace a partir de la necesidad adquirir propiedades de manera rapida y legal y poder asi construir un patrimonio digno, y en ocasiones los financiamientos son demasiados altos o la propiedad no tiene los documentos en regla. Para ello se ha formando un equipo de profesionales con quienes trabaja de la mano para ofreciendote todas herramientas necesarias para ayudarte a construir tus sueños.</p>
                    <section class="home-service-area pb-70">
                        <div class="container">

                            <div class="row">
                                <div class="col-lg-12 col-sm-6" >
                                    <div class="single-serviceI" style="background-image: url('assets/images/solutions-details/nuevo.png'); width: 100%; height:100%; ;
                                    height:700px;" >
                                        <div class="service-imgI"  >
                                        </div>
                    
                                        <div class="service-contentI" >
                                            <h3>Mejorar es el objetivo</h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                        <p align="justify">Fue asi como nace la idea de crear un coorporativo en el cual encuentres todo para poder acercarte lo mas posible a tus deseos de una vivienda. Ofreciendote servicios de <b>bienes raices</b> que cuenta con propiedades para poder realizar inversiones seguras  , <b>construcción</b> donde busca apoyar a tu economia y adaptarse a tus necesidades , <b>abogados</b> quienes te pueden apoyar en cualquier asunto legal ya sea familiar, laboral o cualquier otro , 
                        <b>financiera </b> donde manejamos una tasa de interes adaptable a cada cliente y por ultimo <b>venta y renta de autos</b> si lo que deseas es adquirir un automovil o bien solo rentarlo para realizar ese viaje de negocios o placer.</p>
                    
                </div>
            </div>
        </div>

        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img data-aos="fade-up" data-aos-duration="2500" src="assets/images/solutions-details/ofrece.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-8">
                    <h3></h3>
                    <p align="justify">Desde el inicio este corporativo ha actuado bajo las mismas premisas:</p>
                    <section class="feature-area ptb-100">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="contnet">
                                        <ul>
                                            <li><i class="flaticon-correct"></i> Transparencia</li>
                                            <li><i class="flaticon-correct"></i>Cercanía</li>
                                            <li><i class="flaticon-correct"></i> Compromiso</li>
                                            <li><i class="flaticon-correct"></i> Profesionalidad</li>
                                            <li><i class="flaticon-correct"></i> Innovación</li>
                                            <li><i class="flaticon-correct"></i> Servicio al cliente</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <p align="justify">J G M BUSINESS AND INVESTMENT GROUP, cuenta con bases legales, desde su creación haciendo de manera formal cada uno de los servicios que se prestan, para ofrecerte la confianza que necesitas para lograr tu patrimonio.  </p>
                    <p align="justify">Contamos con profesionales capacitados encada una de las areas, para que resuelvan y aclaren tus dudas mostrandote las mejores opciones con un presupuesto que se adapte a tus necesidades, anteponiendo calidad, profesionalismo y sobre todo lo mas conveniente para el cliente en cada unos de los procesos.</p>

                </div>
            </div>
        </div>
        
        
    </div>
</section>