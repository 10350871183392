import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor() { }


    ngOnInit(): void {}

    accordionItems = [
        {
            title: 'Credito inmediato',
            content:'Te prestamos desde $1,000 hasta $10,000 de manera inmediata, sin necesidad de un aval con tan solo el 15% de interes ',
            open: false
        },
        {
            title: 'Credito de micronegocio',
            content: `Te prestamso desde $5,000 hasta $100,000. Dependiendo de monto y el tiempo, manejamos un aprooximado del 15% al 20%  `,
            open: false
        },
        {
            title: 'Credito hipotecario',
            content: "Valuamos el valor de tu propiedad y te decimos el maximo a obtener en el prestamo con toda la documentación necesaria",
            open: false
        },
        
    ];

    selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}