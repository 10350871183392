<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+529541387341"><i class="bx bxs-phone-call"></i> +52 954 138 7341</a></li>
                        <li><a href="mailto:jgmceo@jgmbusinessgroup.com"><i class="bx bxs-envelope"></i> jgmceo@jgmbusinessgroup.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social"> 
                        <li><a href="https://www.facebook.com/JGMbusinessgroup" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/jgm.servicegroup" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <!--<li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>-->
                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo2.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inicio</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Nosotros</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Origen</a></li>

                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a></li>
                            </ul>
                        </li>

               <!--
                         <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
-->
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Servicios</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Abogados</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Venta y Renta</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bienes Raices</a></li>

                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Constructora</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Financiera</a></li>

                                

                            </ul>
                        </li>

                        
                        
                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contacto </a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Acerca de </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terminos Y Condiciones</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Politica de privacidad</a></li>
                            </ul>
                        </li>

                        
                    </ul>
                </div>
                
            </nav>
        </div>
    </div>
</div>