<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Aviso de Privacidad</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Aviso de Privacidad</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="privecy-area ptb-100">
    <div class="container">
        <h2>Aviso de Privacidad</h2>
        <p align="justify">Este sitio web recopila información personal como nombres, direcciones de correo electrónico y números de teléfono con el fin de mejorar la experiencia del usuario y proporcionar servicios personalizados. Toda la información recopilada se mantiene confidencial y no se comparte con terceros sin su consentimiento. Al utilizar este sitio web, acepta los términos de nuestra política de privacidad.</p>
        
    </div>
</section>