<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Blog Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/images/blog-details/blog-details.jpg" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/blog">May 19, 2023</a></li>
                                <li><span>Posted By:</span> <a routerLink="/blog">John Anderson</a></li>
                            </ul>
                        </div>
                        <h3>Temperature App UX Studies & Development Case</h3>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit, consectetur qui ratione voluptatem sequi nesciunt magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat labore et dolore magna aliqua.</p>
                        <blockquote class="flaticon-quote">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aliquid praesentium eveniet illum asperiores, quidem, ipsum voluptatum numquam ducimus nisi exercitationem dolorum facilis Repellendus aliquid praesentium eveniet illum asperiores.</p>
                        </blockquote>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat consectetur adipisicing onsectetur adipisicing.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat ullamco laboris nisi ut aliquip ex.</p>
                        <div class="row mb-3">
                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="assets/images/blog/4.jpg" alt="Image">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="assets/images/blog/5.jpg" alt="Image">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                                <div class="b-d-s-img">
                                    <img src="assets/images/blog/6.jpg" alt="Image">
                                </div>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam. consectetur adipisicing elit, sed ud exercitation ullamco laboris nisi ut aliquip commodo</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a href="#" target="_blank">Share</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class='bx bx-left-arrow-alt'></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>

                                <ol class="children">
                                    <li class="comment">
                                        <div class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/images/blog-details/comment-img-2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim</p>
                                            </div>
    
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-3.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2023 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>

                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>

                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>

                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url">
                                </p>

                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                </p>

                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search Now</h3>

                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>

                                <button type="submit"><i class='bx bx-search'></i></button>
                            </form>
                        </div>
                    </div>

                    <section class="widget widget-peru-posts-thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2023-06-30">April 20, 2023</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Restaurant Management & Web Developing</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2023-06-30">April 21, 2023</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Ride Share App UX Studeis & Development Case</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2023-06-30">April  22, 2023</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Parking Management & Web Developing</a> </h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                            
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2023-06-30">April 23, 2023</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Temperature App UX Studeis & Development Case</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/blog">January <span>2023</span></a></li>
                                <li><a routerLink="/blog">February <span>2023</span></a></li>
                                <li><a routerLink="/blog">March <span>2023</span></a></li>
                                <li><a routerLink="/blog">April <span>2023</span></a></li>
                                <li><a routerLink="/blog">May <span>2023</span></a></li>
                                <li><a routerLink="/blog">June <span>2023</span></a></li>
                            </ul>
                        </div>
                    </section>
                    
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/blog">Business <span>(10)</span></a></li>
                                <li><a routerLink="/blog">Privacy <span>(20)</span></a></li>
                                <li><a routerLink="/blog">Technology <span>(10)</span></a></li>
                                <li><a routerLink="/blog">Tips <span>(12)</span></a></li>
                                <li><a routerLink="/blog">Uncategorized <span>(16)</span></a></li>
                                <li><a routerLink="/blog">Aiva <span>(17)</span></a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/sign-in">Log in</a></li>
                                <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                <li><a routerLink="/">WordPress.org</a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="post-wrap">
                            <div class="tagcloud">
                                <a routerLink="/">Business (3)</a>
                                <a routerLink="/">Privacy (3)</a>
                                <a routerLink="/">Technology (2)</a>
                                <a routerLink="/">Tips (2)</a>
                                <a routerLink="/">Uncategorized (1)</a>
                                <a routerLink="/">Aiva</a>
                            </div>
                        </div>
                    </section>       
                </aside>
            </div>
        </div>
    </div>
</section>