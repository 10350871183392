<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Venta y Renta de Autos</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Venta y Renta de Autos</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>JGM</span>
            <h2 data-aos="fade-right" data-aos-duration="3000"> Renta de autos</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Económico</h3>
                        <p>Aveo</p>
                        <img data-aos="fade-down" data-aos-duration="2500" src="assets/images/pricing/aveo.jpg" alt="img">
                    </div>
                    <span>$1,100<sub>/MX</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> Limpio en la entrega</li>
                        <li><i class="bx bx-badge-check"></i> Seguro de vida </li>
                        <li><i class="bx bx-badge-check"></i> Maximo 5 pasajeros</li>
                        <li><i class="bx bx-badge-check"></i> Documentación en regla</li>

                    </ul>

                    <a class="box-btn" routerLink="/">Apartar</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Estándar</h3>
                        <p>Jetta</p>
                        <img data-aos="fade-down" data-aos-duration="2500" src="assets/images/pricing/jetta.jpg" alt="img">
                    </div>
                    <span>$1,500 <sub>/MX</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> Limpio en la entrega </li>
                        <li><i class="bx bx-badge-check"></i> Seguro de vida </li>
                        <li><i class="bx bx-badge-check"></i> Maximo 5 pasajeros </li>
                        <li><i class="bx bx-badge-check"></i> Documentación en regla </li>
                    </ul>

                    <a class="box-btn" routerLink="/">Apartar</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Élite</h3>
                        <p>Mazda 3</p>
                        <img data-aos="fade-down" data-aos-duration="2500" src="assets/images/pricing/mazda.jpg" alt="img">
                    </div>
                    <span>$2,100 <sub>/MX</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> Limpio en la entrega</li>
                        <li><i class="bx bx-badge-check"></i> Seguro de vida </li>
                        <li><i class="bx bx-badge-check"></i> Maximo 5 pasajeros</li>
                        <li><i class="bx bx-badge-check"></i> Documentación en regla</li>
                    </ul>

                    <a class="box-btn" routerLink="/">Apartar</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span> </span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Venta de autos </h2>
        </div>
        <p>Adquiere alguna de nuestras unidades </p>
        <img src="assets/images/pricing/venta.jpg" alt="img">
    </div>
</section>