<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo2.png" alt="logo" /></a>
                    </div>
                    <p>Juntos Ganamos Más.</p>

                    

                    <ul class="social">
                        <li><a href="https://www.facebook.com/JGMbusinessgroup" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="https://www.instagram.com/jgm.servicegroup" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Servicios</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/gallery">Bienes Raices</a></li>
                        <li><a routerLink="/pricing">Venta y renta de autos</a></li>
                        <li><a routerLink="/blog">Constructora</a></li>
                        <li><a routerLink="/team">Abogados</a></li>
                        <li><a routerLink="/faq">Financiera</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Enlaces relevantes</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/privecy">Aviso de Privacidad</a></li>
                        <li><a routerLink="/terms-condition">Terminos y condiciones</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contacto</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+529541387341"><i class="bx bx-phone"></i> +52 954 138 7341</a></li>
                        <li><a href="mailto:hello@aiva.com"><i class="bx bxs-envelope"></i> jgmceo@jgmbusinessgroup.com</a></li>
                        <li><i class="bx bxs-map"></i> Cuarta Pte. 207-Int. 10, Col. Sector Hidalgo, 71980 Puerto Escondido, Oax.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Inicio</a></li>
                        <li><a routerLink="/about">Acerca de </a></li>
<!--
                        <li><a routerLink="#">Solutions</a></li>
                        <li><a routerLink="#">Case Studies</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
-->
                        
                        <li><a routerLink="/contact">Contacto</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>©2023 <a href="#" target="_blank">JGM</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>