<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>J G M BUSINESS AND INVESTMENT GROUP </h1>
                                            <p>Juntos Ganamos Más.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Contacto</a>
                                                <a routerLink="/about" class="box-btn border-btn">Conócenos</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>LA MANERA MAS EFECTIVA DE HACERLO, ES HACERLO.</h1>
                                            <p>J G M BUSINESS AND INVESTMENT GROUP</p>
                                            <div class="slider-btn text-center">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>EL CAMINO AL EXITO ES LA ACTITUD</h1>
                                            <p>J G M BUSINESS AND INVESTMENT GROUP</p>
                                            <div class="slider-btn text-center">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<div class="info-area pt-100 pb-70" >
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6" data-aos="fade-left"
            data-aos-delay="300">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> Origen</h3>
                        <div class="arrow">
                            <a routerLink="/case-studies-details"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6" data-aos="fade-right">
           
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Nosotros </h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0" data-aos="fade-right"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/contact-img2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i>Contacto</h3>
                        <div class="arrow">
                            <a routerLink="/contact"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--secciiones omitida
<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>J G M BUSINESS AND INVESTMENT GROUP</span>
            <h2>Concéntrese En Hacer Crecer Sus Finanzas, Confié Su Inversión En Nosotros.</h2>
            <p align="justify">En J G M BUSINESS AND INVESTMENT GROUP, ofrecemos servicios profesionales e integrales, incluyendo auditorías, procedimientos acordados, planificación y supervisión de inventarios, certificaciones y más. Trabajamos en estrecha colaboración con cada cliente para proporcionar soluciones estratégicas y personalizadas que cumplan con sus objetivos financieros y los ayuden a alcanzar el éxito.</p>
            <p align="justify">Si está buscando un corporativo confiable y profesional, no dude en contactarnos para solicitar una cotización. Le aseguramos que, con nuestros servicios profesionales de negocios e inversiones, el manejo de sus finanzas estará en las mejores manos y nosotros nos enfocaremos en hacer crecer su inversión.</p>
            <p align="justify">Para conseguir tus objetivos contamos con los siguientes servicios:</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Bienes Raices</h3>
                        <p>Activos de renta multifamiliar y negocios de calidad institucional. Rentabilidades anuales hasta 17%. Protege tu capital, protege tu futuro. </p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ...</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Constructora</h3>
                        <p>Cumplimos en tiempo y forma en todos los proyectos de construccion, remodelación y adecuación.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ..</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Abogados</h3>
                        <p>Profesionales expertos en variadas áreas. Asesoria jurídica y servicio legales, en el sector público y privado.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ...</a>
                        
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Financiera</h3>
                        <p>Solicita tu préstamo, y haz comodos pagos adaptados a tu economía.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ...</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Venta de vehiculos</h3>
                        <p>Gran veriedad de vehiculos nuevos y seminuevos con garantia. </p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ...</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Renta de vehiculos</h3>
                        <p>Desplazate a cualquier parte, ya sea por placer o negocios, con los mejores precios!.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Más ...</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-right" data-aos-duration="3000">Concéntrese En Hacer Crecer Sus Finanzas, Confié Su Inversión En Nosotros.</h2>
            <p>ofrecemos servicios profesionales e integrales, incluyendo</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6" data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Auditorias</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6" data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2500">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Procedimientos acordados</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6" data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>planificación y supervisión de inventarios</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6" data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3500">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Certificaciones</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-right" data-aos-duration="3000">Nuestros Servicios</h2>
            <p>ofrecemos servicios profesionales e integrales, incluyendo</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-correct"></i>
                    </div>
                    <h3>Bienes Raices</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-calendar"></i>
                    </div>
                    <h3>Constructora</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Financiera</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Abogados</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-goal"></i>
                    </div>
                    <h3>Venta de vehiculos</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4" data-aos="zoom-in">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Renta de vehiculos</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2 data-aos="fade-right" data-aos-duration="3000">Oficinas</h2>
                    <p>Conoce nuestras oficinas.</p>
                    <a routerLink="/contact" class="box-btn">Contacto</a>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="#" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>

<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-right" data-aos-duration="3000">Áreas de servicio</h2>
            <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Bienes Raices
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Contrucción
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Abogados
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Venta y Renta de autos
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                        Financiera
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/comercial.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Propiedades habitables</h3></a>
                                    <p>Casas, departamentos, unidadades habitacionales.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/lote.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Lotes  </h3></a>
                                    <p>Propiedades sin construcción.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/fraccyres.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Residenciales y fraccionamientos</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/construccion1.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Contrucción comercial</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/construccion2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Contruccion residelcial</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/construccion3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Construcción de edificación</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Asesoria Jurica</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Representacion legal</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Negociación </h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/auto1.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/pricing"><h3>Autos nuevos </h3></a>
                                    <p>Precios y caracteristicas a tu medida.</p>
                                    <a routerLink="/pricing" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/auto2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/pricing"><h3>Autos seminuevos</h3></a>
                                    <p>Precios y caracteristicas a tu medida.</p>
                                    <a routerLink="/pricing" class="line-bnt">Ver más</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            
                        </div>

                    </div>
                </div>
                <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                    <div class="row case-list">
                        
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/prest-nego.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/pricing"><h3>prestamo micronegocio</h3></a>
                                    <p>Precios y caracteristicas a tu medida.</p>
                                    <a routerLink="/pricing" class="line-bnt">Ver más</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/prest-per.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/pricing"><h3>prestamo inmediato</h3></a>
                                    <p>Precios y caracteristicas a tu medida.</p>
                                    <a routerLink="/pricing" class="line-bnt">Ver más</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-right" data-aos-duration="3000">El punto de partida cada logro, es el deseo.</h2>
        </div>
        
    </div>
</section>

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 data-aos="fade-right" data-aos-duration="3000">Profesionales a tú servicio</h2>
            <p>Personal capacitado para atenderte y acompañarte en cada proceso</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>persona 1</h3>
                            <p>Licenciado en derecho</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Persona 2</h3>
                            <p>Licenciado de derecho</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Persona 3</h3>
                            <p>Agente de ventas</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Persona 4</h3>
                            <p>Agente de ventas</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>persona 5</h3>
                            <p>Ingeniero civil</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>persona 6</h3>
                            <p>Ingeniero civil</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>persona 7</h3>
                            <p>Licenciado en contabilidad</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>persona 8</h3>
                            <p>Licenciado en contabilidad</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
<!--seccion reutilizable
<section class="home-blog-area pt-100 pb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Joe’s Company Software Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>IT Software Company Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section>
-->
