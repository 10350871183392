<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Constructora</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Constructora</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Construccion</span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Construye tu sueño</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
                

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/edificacion.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>JGM</li>
                            <li><a routerLink="/blog">Ing. Persona1</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Contrucción de edificación</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/residencial.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>JGM</li>
                            <li><a routerLink="/blog">Ing. Persona2</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Construcción Residencial</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/comercial.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>JGM</li>
                            <li><a routerLink="/blog">Ing. Persona3</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Contrucción Comercial</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>


<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/blog/construye.jpg" alt="choose" />

                   
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2 data-aos="fade-right" data-aos-duration="3000">Servicios </h2>
                    <p data-aos="fade-left" data-aos-duration="2000" style="color:rgb(179, 138, 4); size: 12; font-weight:bold; font-size: large;">Construcción<br>
                    Dirección <br>
                    Superintendencia de obra<br>
                    Supervisión de obra <br>
                    Remodelación <br>
                    Restauración</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>