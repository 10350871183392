<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Financiera</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Financiera</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>J G M </span>
            <h2 data-aos="fade-right" data-aos-duration="3000">Ahorrar no es solo guardar, sino saber gastar.</h2>
            <p> Te prestamos sin tantos procesos, de manera rápida y fácil.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-6">
                <div class="single-contact" style="background:#dee4f1;">
                    <div class="icon">
                        <i class='bx bx-happy'></i>
                    </div>

                    <div class="content">
                        <h3>Beneficios</h3>
                        <p>✔ Obtener un crédito de manera fácil<br>
                        ✔ Rápida<br>
                        ✔ Sin tantos requisitos<br>
                        ✔ Sin importar sí estas en el buró de crédito.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-contact" style="background:#dee4f1;">
                    <div class="icon">
                        <i class='bx bx-id-card'></i>
                    </div>

                    <div class="content">
                        <h3>Requisitos</h3>
                        <p >✔ Identificacion oficial<br>
                            ✔ Comprobante de ingresos<br>
                            ✔ Comprobante de domicilio<br>
                            ✔ Número de teléfono/celular</p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>



